import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 719.000000 651.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,651.000000) scale(0.100000,-0.100000)">

<path d="M4333 4290 l-262 -259 1 -104 c1 -182 -44 -304 -160 -428 -112 -119
-233 -178 -401 -195 l-70 -6 -3 52 -3 52 -523 -517 c-287 -285 -521 -519 -519
-522 2 -2 206 73 453 165 l449 169 210 6 c226 6 327 20 451 62 464 159 775
548 826 1038 15 136 1 278 -42 431 -27 96 -96 253 -132 298 -12 15 -40 -9
-275 -242z"/>
<path d="M1909 2034 c-9 -11 -10 -20 -2 -32 14 -24 54 -27 70 -7 28 40 -36 77
-68 39z"/>
<path d="M2869 2034 c-9 -11 -10 -20 -2 -32 14 -24 54 -27 70 -7 28 40 -36 77
-68 39z"/>
<path d="M2040 1875 l0 -155 35 0 35 0 0 155 0 155 -35 0 -35 0 0 -155z"/>
<path d="M2170 1875 l0 -155 35 0 35 0 0 155 0 155 -35 0 -35 0 0 -155z"/>
<path d="M1560 1865 l0 -145 95 0 c103 0 132 9 174 54 34 36 40 118 12 164
-37 57 -73 72 -183 72 l-98 0 0 -145z m200 67 c13 -10 23 -33 27 -59 5 -35 2
-45 -20 -67 -22 -21 -35 -26 -79 -26 l-53 0 3 85 3 85 49 0 c31 0 55 -6 70
-18z"/>
<path d="M4410 1885 c0 -104 2 -125 15 -125 12 0 15 14 15 64 0 56 3 67 22 80
30 21 44 20 68 -4 16 -16 20 -33 20 -81 0 -44 3 -60 13 -56 8 2 11 24 9 73 -3
55 -7 73 -24 87 -23 19 -60 22 -89 7 -17 -10 -19 -7 -19 35 0 33 -4 45 -15 45
-13 0 -15 -21 -15 -125z"/>
<path d="M5060 1885 c0 -104 2 -125 15 -125 13 0 15 21 15 125 0 104 -2 125
-15 125 -13 0 -15 -21 -15 -125z"/>
<path d="M3300 1976 c0 -19 -5 -26 -20 -26 -16 0 -20 -7 -20 -30 0 -23 4 -30
20 -30 18 0 20 -7 20 -65 0 -53 4 -68 22 -85 22 -20 81 -27 104 -12 21 13 -2
52 -31 52 -24 0 -25 3 -25 55 0 52 1 55 25 55 21 0 25 5 25 30 0 25 -4 30 -24
30 -18 0 -25 6 -28 23 -2 17 -11 23 -35 25 -30 3 -33 1 -33 -22z"/>
<path d="M3820 1990 c0 -5 18 -10 40 -10 l40 0 0 -110 c0 -91 3 -110 15 -110
12 0 15 19 15 110 l0 110 40 0 c22 0 40 5 40 10 0 6 -38 10 -95 10 -57 0 -95
-4 -95 -10z"/>
<path d="M1910 1835 l0 -115 35 0 35 0 0 115 0 115 -35 0 -35 0 0 -115z"/>
<path d="M2300 1794 l0 -154 35 0 35 0 0 50 c0 47 1 50 19 40 11 -5 36 -10 56
-10 71 0 124 90 95 161 -23 56 -51 68 -151 68 l-89 -1 0 -154z m169 70 c12
-28 6 -64 -13 -76 -17 -12 -61 -9 -74 4 -7 7 -12 26 -12 43 0 39 16 55 55 55
25 0 35 -6 44 -26z"/>
<path d="M2629 1934 c-32 -21 -59 -64 -59 -94 0 -36 28 -85 59 -103 55 -32
140 -15 175 35 26 37 21 103 -12 139 -24 27 -37 33 -83 36 -39 3 -61 -1 -80
-13z m109 -56 c7 -7 12 -26 12 -43 0 -17 -5 -36 -12 -43 -17 -17 -64 -15 -82
4 -19 18 -20 34 -4 69 8 19 19 25 43 25 17 0 36 -5 43 -12z"/>
<path d="M2870 1835 l0 -115 35 0 35 0 0 115 0 115 -35 0 -35 0 0 -115z"/>
<path d="M3000 1833 l0 -113 35 0 35 0 0 74 c0 82 11 100 57 94 28 -3 28 -3
31 -85 l3 -83 34 0 35 0 0 89 c0 79 -2 91 -24 114 -22 24 -29 25 -115 25 l-91
-1 0 -114z"/>
<path d="M3488 1935 c-32 -18 -44 -54 -29 -83 11 -20 52 -38 100 -44 14 -2 27
-9 29 -17 4 -12 -7 -13 -61 -8 -61 6 -66 4 -72 -14 -10 -31 9 -43 78 -47 52
-3 66 -1 90 18 30 24 37 69 12 90 -8 6 -36 17 -61 24 -26 7 -50 18 -52 26 -4
10 7 12 52 7 53 -5 56 -4 62 19 4 14 4 28 0 31 -15 15 -121 13 -148 -2z"/>
<path d="M4037 1920 c-34 -27 -43 -70 -23 -109 19 -36 44 -51 83 -51 30 0 73
24 62 34 -3 4 -18 2 -33 -4 -44 -16 -96 8 -96 44 0 3 34 6 76 6 l76 0 -6 28
c-15 63 -89 91 -139 52z m93 -20 c32 -32 24 -40 -40 -40 -62 0 -69 5 -47 32
25 32 60 35 87 8z"/>
<path d="M4233 1916 c-60 -52 -20 -156 60 -156 33 1 76 25 64 36 -3 4 -19 1
-35 -5 -41 -17 -78 -1 -87 39 -13 59 43 107 90 76 29 -19 46 -2 19 19 -29 22
-79 18 -111 -9z"/>
<path d="M4630 1850 c0 -73 3 -90 15 -90 12 0 15 14 15 64 0 56 3 67 22 80 30
21 44 20 68 -4 16 -16 20 -33 20 -80 0 -33 4 -60 10 -60 6 0 10 31 10 74 0 66
-3 77 -22 90 -26 18 -61 20 -89 6 -13 -7 -19 -7 -19 0 0 5 -7 10 -15 10 -12 0
-15 -17 -15 -90z"/>
<path d="M4877 1929 c-27 -16 -50 -68 -42 -98 18 -72 102 -97 150 -45 35 38
34 96 -1 129 -28 26 -75 32 -107 14z m91 -34 c22 -21 18 -76 -8 -97 -29 -23
-41 -23 -74 3 -28 22 -32 44 -15 83 17 37 65 42 97 11z"/>
<path d="M5174 1925 c-34 -23 -49 -69 -34 -105 39 -95 170 -73 170 28 0 74
-78 118 -136 77z m84 -21 c33 -23 31 -88 -3 -110 -21 -14 -30 -14 -55 -4 -34
14 -44 38 -35 81 10 44 54 60 93 33z"/>
<path d="M5373 1916 c-22 -19 -28 -32 -28 -66 0 -34 6 -47 28 -66 33 -28 82
-31 109 -7 17 15 18 15 18 -1 0 -49 -42 -73 -100 -56 -40 12 -52 4 -26 -16 25
-18 95 -18 119 0 25 20 37 69 37 158 0 63 -3 78 -15 78 -8 0 -15 -7 -15 -17 0
-15 -1 -15 -18 0 -27 24 -76 21 -109 -7z m110 -28 c24 -28 21 -55 -8 -83 -27
-28 -37 -30 -65 -15 -66 35 -35 141 35 123 11 -2 28 -14 38 -25z"/>
<path d="M5560 1934 c0 -3 16 -39 35 -81 19 -42 35 -86 35 -99 0 -24 -33 -50
-50 -39 -6 3 -15 3 -21 -1 -8 -4 -7 -9 2 -15 23 -14 55 -10 72 9 27 29 108
232 93 232 -8 0 -27 -31 -42 -70 -16 -38 -32 -70 -35 -70 -3 0 -18 32 -33 70
-16 41 -34 70 -42 70 -8 0 -14 -3 -14 -6z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
